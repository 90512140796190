export enum MoveType {
  ATM = "ATM", // 2007, 2011, 2015, 2017, 2035, 2039, 2040, 2049
  COMPRA = "COMPRA", //   1001, 1002, 1003, 1004, 1011, 1012, 1014, 1018, 1019
  SEGURO = "SEGURO", // 600,1001,1012,1014
  COMISION = "COMISION", // 70,72,4065,6000,6003,6005,104
  CORRPROM = "CORRPROM", // 205,209
  CORRPROMLINEX_PMEN = "CORRPROMLINEX_PMEN", // 210
  CORRPROMLINEX_AMOR = "CORRPROMLINEX_AMOR", // 214
  PREFOLIO = "PREFOLIO" // Compra con etiqueta de "PROCESO"
}
